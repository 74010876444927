body {
  margin: 0;
}

div:focus {
  outline: none;
}

* {
  transition: color, background-color 100ms ease-in;
}
